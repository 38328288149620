import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import BroadwayBoiseImage from '../../assets/images/publication-banners/broadway_boise.jpg';

export const query = graphql`
  query BoiseQuery {
    allBibCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function BroadwayBoise({ data }) {
  const bibNodes = data.allBibCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/broadway-in-boise/';
  // append the image reference with the path to the image via the static folder
  return (
    <Layout>
      <Helmet>
        <title>Broadway in Boise - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Broadway in Boise’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Broadway in Boise</h1>
            </header>
            <span className="image main">
              <img src={BroadwayBoiseImage} alt="" />
            </span>
            <p className="box">
              Broadway isn’t just for New York—you’ll find it in Boise, Idaho
              too! Every year, audiences return to the Morrison Center for
              thrills, laughs, and music of all kinds. Place an ad in these
              programs to reach a devoted audience every season.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://www.morrisoncenter.com/broadway/broadway-events"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Broadway in Boise Website
                </a>
              </li>
            </ul>
            <h2>
              Broadway in Boise <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {bibNodes.map((node) => {
                return (
                  <div className="col-4 box" key={node.id}>
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                    <h3>{node.SHOW}</h3>
                    <strong>{node.RUNNING_DATES}</strong> <br />
                    <br />
                    <h5>Advertising Information</h5>
                    <section className="box">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              File Ready Deadline: <br />
                              {node.FR}
                            </td>
                          </tr>
                          {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                          <tr>
                            <td>Print Quantity: {node.Quantity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
